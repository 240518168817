import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';

import router from './routes/sefiRouter';
import axios from 'axios';

import i18n from './i18n';
import './registerServiceWorker'

Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

Vue.prototype.getOfflineSigner = window.getOfflineSigner;
Vue.prototype.getEnigmaUtils = window.getEnigmaUtils;

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n
});
