<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright">
        <span v-if="updateExists === true">
                  <base-button
                    type="primary"
                    size="sm"
                    @click.native="refreshApp"
                    >New update available, plese refresh!
                  </base-button>
      <br />
      </span>
        <a href="https://sdex.ch/" target="_blank"
          ><span style="color: #D52B1E;">SDEX</span
          ><span style="color: #FFF;">.CH</span></a
        >
        &copy; 2020 - {{ year }}. This site is cookieless. <br />Raw blockchain
        data source:
        <a href="https://datahub.figment.io">Datahub by Figment</a> <br />Based
        on <a href="https://scrt.network/">Secret Network</a>
      </div>
    </div>
  </footer>
</template>
<script>
import update from "../../mixins/update";
import { BaseAlert } from "src/components";

export default {
  components: {
    BaseAlert
  },
  data() {
    return {
      year: new Date().getFullYear(),
      type: ["", "info", "success", "warning", "danger"],
      notifications: {
        topCenter: false
      }
    };
  },
  mixins: [update],
  methods: {
    notifyVue(verticalAlign, horizontalAlign) {
      let color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        message:
          "New version is available",
        timeout: 30000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color]
      });
    }
  }
};
</script>
<style>
.card .alert {
  position: relative !important;
  width: 100%;
}

.modal-body,
.modal-footer {
  padding: 24px 24px 16px 24px;
}
</style>
