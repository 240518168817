export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },
  created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
    })

  },
  methods: {
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp() {
      window.location.reload();
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });        
    },
  },
}